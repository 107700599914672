<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingBusiness">
              <ValidationObserver
                v-if="business"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateBusiness)"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.owner.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.owner.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.name"
                        name="name"
                        rules="required"
                        label="Company Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.owner.email"
                        name="owner.email"
                        rules="required|email"
                        label="Email"
                      />
                    </div>
                    <div class="col-md-6">
                      <PhoneInput
                        v-model="business.owner.phone_number"
                        label="Phone Number"
                        rules=""
                      />
                    </div>
                    <div class="col-md-6">
                      <SelectInput
                        v-model="business.timezone"
                        :options="timezones"
                        label="Timezone"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Messages Reset Day <span v-b-tooltip.hover class="cursor-pointer" title="You can set the day of the month when you want the message limits to reset"><i  class="uil uil-question-circle"></i></span></label>
                        <SelectInput
                          v-model="business.reset_at"
                          :options="days"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Created At</label>
                        <flat-pickr
                          v-model="business.created_at"
                          :config="dateTimePicker"
                          class="form-control"
                          placeholder="Pick a date"
                          :disabled="true"
                        ></flat-pickr>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3"></div>
                    <div class="col-md-6">
                      <label>Message Limit</label>
                      <div class="mb-2 d-flex">
                        <div class="pt-2">
                          <b-form-checkbox
                            v-model="business.message_limit"
                            name="check-button"
                            switch
                            inline
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="flex-fill">
                          <TextInput
                            v-model="business.no_messages"
                            :readonly="!business.message_limit"
                            type="number"
                            name="no_messages"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label>Contact Limit</label>
                      <div class="mb-2 d-flex">
                        <div class="pt-2">
                          <b-form-checkbox
                            v-model="business.contact_limit"
                            name="check-button"
                            switch
                            inline
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="flex-fill">
                          <TextInput
                            v-model="business.no_contacts"
                            :readonly="!business.contact_limit"
                            type="number"
                            name="no_contacts"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscription plan</label>
                        <select
                          v-model="business.stripe_plan"
                          class="form-control"
                          :disabled="business.default_payment_method === null"
                        >
                          <option
                            v-for="plan in businessPlans"
                            :key="plan.id"
                            :value="plan.id"
                            >{{ plan.name }}</option
                          >
                        </select>
                        <div
                          v-if="!business.free_fee"
                          class="mt-2 d-flex justify-content-between"
                        >
                          <span
                            >Status:
                            <strong
                              :class="{
                                'text-primary':
                                  business.stripe_status === 'active',
                                'text-danger':
                                  business.stripe_status === 'canceled' ||
                                  business.stripe_status === 'past_due',
                              }"
                              >{{ business.stripe_status }}</strong
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-4 mt-2">
                        <div class="mt-4 pt-2">
                          <span class="pr-2" style="font-weight:600;">OFF</span>
                          <b-form-checkbox
                            v-model="business.free_fee"
                            name="check-button"
                            switch
                            inline
                          >
                            ON - Free fee
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Livechat plan</label>
                        <select
                          v-model="business.livechat_plan"
                          class="form-control"
                          :disabled="business.default_payment_method === null"
                        >
                          <option
                            v-for="plan in livechatPlans"
                            :key="plan.id"
                            :value="plan.id"
                            >{{ plan.name }}</option
                          >
                        </select>
                        <div
                          v-if="business.livechat_status"
                          class="mt-2 d-flex justify-content-between"
                        >
                          <span
                            >Status:
                            <strong
                              :class="{
                                'text-primary':
                                  business.livechat_status === 'active',
                                'text-danger':
                                  business.livechat_status === 'canceled' ||
                                  business.livechat_status === 'past_due',
                              }"
                              >{{ business.livechat_status }}</strong
                            ></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 pb-4">
                      <div class="mt-3 pt-4">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox
                          v-model="business.is_active_livechat"
                          name="check-button"
                          switch
                          inline
                        >
                          ON - Livechat
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Loop plan</label>
                        <select
                          v-model="business.loop_plan"
                          class="form-control"
                          :disabled="business.default_payment_method === null"
                        >
                          <option
                            v-for="plan in loopPlans"
                            :key="plan.id"
                            :value="plan.id"
                            >{{ plan.name }}</option
                          >
                        </select>
                        <div
                          v-if="business.loop_status"
                          class="mt-2 d-flex justify-content-between"
                        >
                          <span
                            >Status:
                            <strong
                              :class="{
                                'text-primary':
                                  business.loop_status === 'active',
                                'text-danger':
                                  business.loop_status === 'canceled' ||
                                  business.loop_status === 'past_due',
                              }"
                              >{{ business.loop_status }}</strong
                            ></span
                          >
                          <div
                            v-if="business.loop_status !== 'canceled'"
                            class="mt-1"
                          >
                            <button
                              class="ml-1 btn btn-danger btn-sm"
                              href="javascript:void;"
                              @click.prevent="cancelSubscription('loop')"
                            >
                              Cancel subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 pb-4">
                      <div class="mt-3 pt-4">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox
                          v-model="business.is_active_loop"
                          name="check-button"
                          switch
                          inline
                        >
                          ON - Loop
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 pb-4">
                      <div class="mt-3 pt-4">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox v-model="business.is_enable_import_contact" name="check-button" switch inline>
                          ON - Import contact
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else>Update</span>
                      </b-button>
                      <b-button
                        variant="primary"
                        :disabled="loadingImpersonate"
                        @click="impersonate"
                      >
                        <b-spinner v-if="loadingImpersonate" small />
                        <span v-else
                          >Log in <i class="uil uil-sign-out-alt"></i
                        ></span>
                      </b-button>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h5 class="h5">Billing</h5>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }">
              <form @submit.prevent="handleSubmit(updateBilling)">
                <div class="row">
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_per_sms"
                      type="number"
                      label="Cost Per SMS($)"
                      name="costPerSms"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_per_mms"
                      type="number"
                      label="Cost Per MMS($)"
                      name="costPerMms"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_per_call"
                      type="number"
                      label="Cost Per Minute($)"
                      name="costPerCall"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.outbound_carrier_fee"
                      type="number"
                      label="Outbound Carrier Fee($)"
                      name="outboundCarrierFee"
                      rules="required"
                    />
                  </div>
                  <div class="col-md-3">
                    <TextInput
                      v-model="settings.cost_number_provision"
                      type="number"
                      label="Number Provisioning($)"
                      name="numberProvisioning"
                      rules="required"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingBilling || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingBilling" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="passwordForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(changePassword)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password"
                      name="password"
                      rules="required|confirmed:confirm_password"
                      label="Password"
                      type="password"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password_confirmation"
                      vid="confirm_password"
                      name="confirm_password"
                      rules="required"
                      label="Confirm Password"
                      type="password"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingPassword || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      loadingBilling: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      dateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
      },
      days: [],
      settings: {
      },
      loadingImpersonate: false,
    }
  },

  computed: {
    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },

    timezones() {
      return this.$store.getters['app/timezones']
    },

    livechatPlans() {
      return this.plans.filter(p => p.is_livechat_plan)
    },

    loopPlans() {
      return this.plans.filter(p => p.is_loop_plan)
    },

    businessPlans() {
      let plans = this.$store.getters['subscription/allBusinessPlans'] || []
      
      return plans.filter(
        (plan) => plan.visible || plan.id === this.user.stripe_plan
      )
    },
  },

  mounted() {
    this.days = Array.from(Array(31).keys()).map((item) => {
      return {
        label: item+1,
        value: item+1
      }
    })

    this.getBusiness()
    this.getPlans()

    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }
  },

  methods: {
    getPlans() {
      if (this.$store.getters['subscription/allBusinessPlans']) return

      this.loadingPlans = true
      this.$store
        .dispatch('subscription/getAllBusinessPlans')
        .then(() => {
          this.loadingPlans = false
        })
        .catch(() => {
          this.loadingPlans = false
        })
    },

    updateBusiness() {
      this.loadingUpdate = true

      this.$store
        .dispatch('business/update', this.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getBusiness() {
      this.loadingBusiness = true

      this.$store
        .dispatch('business/find', this.$route.params.id)
        .then((business) => {
          this.business = business
          this.settings = business.owner.settings
          this.business.message_limit = business.no_messages != null
          this.business.contact_limit = business.no_contacts != null
          this.loadingBusiness = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.businesses.index' })
          this.loadingBusiness = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },

    updateBilling() {
      this.loadingBilling = true

      this.$store
        .dispatch('business/adminUpdateBilling', {
          businessId: this.business.id,
          settings: this.settings,
        })
        .then(() => {
          this.loadingBilling = false
        })
        .catch(() => {
          this.loadingBilling = false
        })
    },

    impersonate() {
      this.loadingImpersonate = true
      const user = this.business.owner
      if (user) {
        this.$store
          .dispatch('auth/impersonate', user.id)
          .then((res) => {
            this.$store
              .dispatch('auth/getProfile')
              .then((user) => {
                this.$router.push('/' + user.role.toLocaleLowerCase())
                this.loadingImpersonate = false
              })
              .catch(() => {
                this.loadingImpersonate = false
              })
          })
          .catch(() => {
            this.loadingImpersonate = false
          })
      }
    },
  },
}
</script>
